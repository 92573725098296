import { createSlice } from "@reduxjs/toolkit";

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  layoutTheme,
  layoutPositions,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";

import * as constant from "../../constants/global";

interface LayoutStateType {
  layoutType  : string;
  layoutWidth : string;
  leftSideBarTheme : string;
  leftSideBarType : any;
  layoutMode : any;
  topbarTheme : any;
  isPreloader : boolean;
  showRightSidebar : boolean;
  layoutPosition : any;
  isMobile : boolean;
  showSidebar : boolean;
  leftMenu : boolean;
  desktopNotification: string;
  competitionFilter: string,
  competitionFilterColors: any
}

const getCompetitionFilter = () => {
    const competitionFilterVar: any = localStorage.getItem(constant.competition_filter);
    if (!competitionFilterVar) return '[]';
    return competitionFilterVar;
};
export const initialState: LayoutStateType = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  layoutMode: layoutTheme.LIGHTMODE,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: true,
  showRightSidebar: false,
  layoutPosition: layoutPositions.SCROLLABLE_FALSE,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  desktopNotification:  '',
  competitionFilter: getCompetitionFilter(),
  competitionFilterColors: ['primary', 'secondary', 'success', 'danger', 'purple', 'dark']
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeLayoutAction(state, action) {
      state.layoutType = action.payload
    },
    changeTopbarThemeAction(state, action) {
      state.topbarTheme = action.payload
    },
    changeSidebarThemeAction(state, action) {
      state.leftSideBarTheme = action.payload
    },
    changeLayoutWidthAction(state, action) {
      state.layoutWidth = action.payload
    },
    changeSidebarTypeAction(state, action) {
      state.leftSideBarType = action.payload
    },
    changeLayoutModeAction(state, action) {
      state.layoutMode = action.payload
    },
    changeLayoutPositionAction(state, action) {
      state.layoutPosition = action.payload
    },
    changeDesktopNotificationAction(state, action) {
      localStorage.setItem(constant.desktop_notification, action.payload)
      state.desktopNotification = action.payload
    },
    addCompetitionFilterAction(state, action) {
      const array_competition = JSON.parse(state.competitionFilter);

      if(array_competition.indexOf(action.payload) == -1){
        array_competition.push(action.payload)
      }
      const save_array_competition = JSON.stringify(array_competition)
      localStorage.setItem(constant.competition_filter, save_array_competition);
      state.competitionFilter = save_array_competition

    },
    removeCompetitionFilterAction(state, action) {
      const array_competition = JSON.parse(state.competitionFilter);

      const index_array = array_competition.indexOf(action.payload)
      if(index_array > -1){
          array_competition.splice(index_array, 1)
      }

      const save_array_competition = JSON.stringify(array_competition)
      localStorage.setItem(constant.competition_filter, save_array_competition);
      state.competitionFilter = save_array_competition

    }
  }
});

export const {
  changeLayoutAction,
  changeTopbarThemeAction,
  changeSidebarThemeAction,
  changeLayoutWidthAction,
  changeSidebarTypeAction,
  changeLayoutModeAction,
  changeLayoutPositionAction,
  changeDesktopNotificationAction,
  addCompetitionFilterAction,
  removeCompetitionFilterAction
} = layoutSlice.actions

export default layoutSlice.reducer;
