import React, {useEffect} from "react";

import {
    changeLayout,
    changeLayoutWidth,
    changeLayoutPosition,
    changelayoutMode,
    changeTopbarTheme,
    changeSidebarType,
    changeSidebarTheme,
    changeDesktopNotification
} from "../../slices/thunks";

//constants
import {
  layoutTypes,
  layoutTheme,
  layoutWidthTypes,
  layoutPositions,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import {withTranslation} from "react-i18next";
import * as constant from "../../constants/global";
const RightSidebar = (props: any) => {
  const dispatch = useDispatch();

  const {
    layoutType,
    layoutMode,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    desktopNotification
  } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
    layoutMode: state.Layout.layoutMode,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    desktopNotification: state.Layout.desktopNotification == 'active'
  }));

    useEffect(() => {
        const desktop_notification = localStorage.getItem(constant.desktop_notification);

        if(desktop_notification !==  null){
            dispatch(changeDesktopNotification(desktop_notification));
        }else{
            dispatch(changeDesktopNotification('not-active'));
        }

    }, [])

  return (
    <React.Fragment>
      <div>
        <SimpleBar className="vh-100">

          <div className="rightbar-title d-flex align-items-center bg-dark p-3">
            <h5 className="m-0 me-2 text-white">{props.t("Settings")}</h5>
            <Link
              to="#"
              onClick={props.onClose}
              className="right-bar-toggle ms-auto"
            >
              <i className="mdi mdi-close noti-icon"></i>
            </Link>
          </div>

          <hr className="m-0" />

          <div className="p-4">
            <h6 className="mb-3">{props.t("Desktop notifications")}</h6>
            <div className="form-check form-check-inline">
                  <input
                      type="radio"
                      id="radioDesktopNotificationOff"
                      name="desktopNotification"
                      className="form-check-input"
                      checked={!desktopNotification}
                      onChange={e => {
                            dispatch(changeDesktopNotification('not-active'));
                      }}
                  />
                  <label htmlFor="radioDesktopNotificationOff" className="form-check-label">
                      {props.t("Off")}
                  </label>
              </div>
            {"   "}
            <div className="form-check form-check-inline">
                  <input
                      className="form-check-input"
                      type="radio"
                      name="desktopNotification"
                      id="radioDesktopNotificationOn"
                      checked={desktopNotification}
                      onChange={e => {
                          dispatch(changeDesktopNotification('active'));
                      }}
                  />
                  <label className="form-check-label" htmlFor="radioDesktopNotificationOn">
                      {props.t("On")}
                  </label>
              </div>

          </div>

        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </React.Fragment>
  );
};
export default withTranslation()(RightSidebar);
