export const cookie_token = 'token'
export const desktop_notification = 'desktop_notification'
export const competition_filter = 'competitionFilter'
export const time_start_filter = 'time_start_filter'
export const time_end_filter = 'time_end_filter'
export const time_score_start_filter = 'time_score_start_filter'
export const time_score_end_filter = 'time_score_end_filter'
export const home_score_filter = 'home_score_filter'
export const home_score_status_filter = 'home_score_status_filter'
export const away_score_filter = 'away_score_filter'
export const away_score_status_filter = 'away_score_status_filter'

export const date_start_filter = 'date_start_filter'
export const date_end_filter = 'date_end_filter'

export const total_goals_filter = 'total_goals_filter'
export const total_goals_status_filter = 'total_goals_status_filter'

export const analytics_not_active_set_graph = 'analytics_not_active_set_graph'

export const cookie_remember_login = 'remember_login'
export const cookie_email_login = 'email_login'
export const cookie_password_login = 'password_login'

export const toggle_menu = 'toggle_menu'

export const validEmailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
export const validPasswordRegex = RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,255}$/)
export const validUserName = RegExp(/^[A-Za-z0-9]{5,20}$/)

export const odds_filter = 'odds_filter'

export const odds_filter_status = 'odds_filter_status'
