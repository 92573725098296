import { createAsyncThunk } from "@reduxjs/toolkit";
import * as url from "../../helpers/url_helper";
import {ApiCore} from "../../helpers/api_helper";
import {SET_STRATEGY_STATUS_LAST_WIN} from "../../helpers/url_helper";
const api = new ApiCore();


export const getStrategyAll = createAsyncThunk(
    "user/alert-web-all",
    async () => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.get(
                url.GET_STRATEGY + '/all?lang='+lang
            );
        } catch (error) {
            return error;
        }
    }
);
export const getStrategy = createAsyncThunk(
  "user/alert-web",
  async () => {
      try {
          const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
          return api.get(
              url.GET_STRATEGY + '?lang='+lang
          );
      } catch (error) {
          return error;
      }
  }
);

export const getStrategyDetailsById = createAsyncThunk(
    "user/alert",
    async (strategyId: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.get(
                url.GET_STRATEGY_DETAILS_BY_ID + '/' + strategyId + '?lang='+lang
            );
        } catch (error) {
            return error;
        }
    }
);

export const setStrategyActive = createAsyncThunk(
    "user/update-strategy-active",
    async (strategyId: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.SET_STRATEGY_ACTIVE + '/' + strategyId + '?lang='+lang,
                {}
            );
        } catch (error) {
            return error;
        }
    }
);

export const setStrategyAlertLastWin = createAsyncThunk(
    "user/update-strategy-last-win",
    async (strategy: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.SET_STRATEGY_LAST_WIN + '/' + strategy.alert_id + '/' + strategy.value + '?lang='+lang,
                {}
            );
        } catch (error) {
            return error;
        }
    }
);

export const setStrategyAlertStatusLastWin = createAsyncThunk(
    "user/update-strategy-status-last-win",
    async (strategy: any) => {
        try {
            console.log('strategy : ', strategy)
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.SET_STRATEGY_STATUS_LAST_WIN + '/' + strategy.alert_id + '/' + strategy.value + '?lang='+lang,
                {}
            );
        } catch (error) {
            return error;
        }
    }
);

export const setStrategyAlertAverage = createAsyncThunk(
    "user/update-strategy-average",
    async (strategy: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.SET_STRATEGY_AVERAGE + '/' + strategy.alert_id + '/' + strategy.value + '?lang='+lang,
                {}
            );
        } catch (error) {
            return error;
        }
    }
);

export const setStrategyAlertStatusAverage = createAsyncThunk(
    "user/update-strategy-status-average",
    async (strategy: any) => {
        try {
            const lang = localStorage.getItem("I18N_LANGUAGE") || "en"
            return api.create(
                url.SET_STRATEGY_STATUS_AVERAGE + '/' + strategy.alert_id + '/' + strategy.value + '?lang='+lang,
                {}
            );
        } catch (error) {
            return error;
        }
    }
);
