interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    subItems ?: any;
    isHeader ?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Dashboards",
        isHeader : true
    },
    {
        id: 2,
        label: "Games",
        icon: "monitor",
        link: "/games"
    },
    {
        id: 139,
        label: "Analytics",
        icon: "bar-chart-2",
        link: "/analytics"
    },
    {
        id: 141,
        label: "Strategy",
        icon: "grid",
        link: "/strategy"
    }
];

export { menuItems };
