import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Button} from "reactstrap";

//import countup
import CountUp from "react-countup";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getStrategy} from "../../slices/Strategy/thunk";
import * as constant from '../../constants/global';

const DashboardAnalytics = (props : any) => {

    const dispatch = useDispatch();

    const {error, loading, strategies} = useSelector((state: any) => ({
        error: state.strategy.error,
        loading: state.strategy.loading,
        strategies: state.strategy.strategies ?
            state.strategy.strategies.map( (strategy: any) =>
            {
                return ({
                    'id': strategy.id == null ? -1 : strategy.id,
                    'color': strategy.id == null ? '#40C706' : strategy.colorBG,
                    'description': strategy.description,
                    'data': strategy.series.map((data: any) => {
                        return (
                            {
                                x: data['timeframe'],
                                y: data['win_total'],
                                goals: [
                                    {
                                        name: strategy.id == null ? '' : props.t("Average") +  ' ' + Math.round((parseInt(data['win_total']) + parseInt(data['lost_total'])) / parseInt(data['win_total'])) + ' ' +   props.t("Chances") + ' ',
                                        value: parseInt(data['win_total']) + parseInt(data['lost_total']),
                                        strokeWidth: 5
                                    }
                                ]
                            }
                        );
                     })
                });
            }
            )
            : []
    }));

    const [forceLoading, setForceLoading] = useState(true);
    // @ts-ignore
    const [notActiveSet, setNotActiveSet] = useState(localStorage.getItem(constant.analytics_not_active_set_graph) != null ? JSON.parse(localStorage.getItem(constant.analytics_not_active_set_graph)) : [])

    const dispatchGetStrategy = ()=>{
        setForceLoading(false);
        dispatch(getStrategy());
    }

    useEffect(() => {
        dispatch(getStrategy());
        const timer = setInterval(dispatchGetStrategy, 30000);
        return () => clearInterval(timer);
    }, []);

    const displaySeries = (strategyId: number) => {
        // @ts-ignore
        let analytics_not_active_set = JSON.parse(localStorage.getItem(constant.analytics_not_active_set_graph))

        if(analytics_not_active_set == null){
            localStorage.setItem(constant.analytics_not_active_set_graph, JSON.stringify([strategyId]))
            setNotActiveSet([strategyId])
        }else{

            const index_array = analytics_not_active_set.indexOf(strategyId)
            if(index_array > -1){
                analytics_not_active_set.splice(index_array, 1)
            }else{
                analytics_not_active_set.push(strategyId)
            }
            setNotActiveSet(analytics_not_active_set)
            localStorage.setItem(constant.analytics_not_active_set_graph, JSON.stringify(analytics_not_active_set))
        }
    }

    const options = {
        chart: {
        },
        plotOptions: {
            bar: {
                columnWidth: "95%",
            },
        },
        colors: strategies.map((data: any) => data.color),
        dataLabels: {
            enabled: !1
        },
        legend: {
            show: false
        }
    };

    const [menu1, setMenu1] = useState<boolean>(!1);
    return (
        <Card className="card-h-100">
            <CardBody>
                <div className="float-end">
                    <Dropdown
                        isOpen={menu1}
                        toggle={() => setMenu1(!menu1)}
                    >
                        <DropdownToggle tag="a" className="text-reset">
                            <span className="fw-semibold">Sort By:</span>
                            <span className="text-muted">Yearly<i className="mdi mdi-chevron-down ms-1"></i></span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem to="#">Yearly</DropdownItem>
                            <DropdownItem to="#">Monthly</DropdownItem>
                            <DropdownItem to="#">Weekly</DropdownItem>
                            <DropdownItem to="#">Today</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <h4 className="card-title mb-4">{props.t("Strategies analytics")}</h4>
                <div className="mt-1">
                    <ul className="list-inline main-chart mb-0 text-center">
                        <li className="list-inline-item chart-border-left me-0 border-0">
                            <h3 className="text-primary">
                                <span><CountUp prefix="" suffix="" start={0}
                                               end={strategies.filter((data: any) => !(notActiveSet.indexOf(data.id) > -1)).map((dat: any) =>
                                                    dat.data.map((som: any) => parseInt(som.goals[0].value)).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                                   ).reduce((partialSum: any, a: any) => partialSum + a, 0)} decimals={0} duration={2.75} /></span><span
                                    className="text-muted d-inline-block fw-normal font-size-15 ms-2">{props.t("Chances")}</span>
                            </h3>
                        </li>
                        <li className="list-inline-item chart-border-left me-0">
                            <h3><span><CountUp start={0} end={strategies.filter((data: any) => !(notActiveSet.indexOf(data.id) > -1)).map((dat: any) =>
                                dat.data.map((som: any) => parseInt(som.y)).reduce((partialSum: any, a: any) => partialSum + a, 0)
                            ).reduce((partialSum: any, a: any) => partialSum + a, 0)} duration={2.75} /></span><span
                                className="text-muted d-inline-block fw-normal font-size-15 ms-2">{props.t("Winnings")}</span>
                            </h3>
                        </li>
                        <li className="list-inline-item chart-border-left me-0">
                            <h3><span><CountUp start={0} end={Math.round(
                                (strategies.filter((data: any) => !(notActiveSet.indexOf(data.id) > -1)).map((dat: any) =>
                                    dat.data.map((som: any) => parseInt(som.goals[0].value)).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                ).reduce((partialSum: any, a: any) => partialSum + a, 0))
                                /
                                (strategies.filter((data: any) => !(notActiveSet.indexOf(data.id) > -1)).map((dat: any) =>
                                    dat.data.map((som: any) => parseInt(som.y)).reduce((partialSum: any, a: any) => partialSum + a, 0)
                                ).reduce((partialSum: any, a: any) => partialSum + a, 0))

                            )} suffix="" duration={2.75} /></span>
                                <span className="text-muted d-inline-block fw-normal font-size-15 ms-2">{props.t("Average")}</span>
                            </h3>
                        </li>
                    </ul>
                </div>
                <div className="mt-3">
                    <div id="strategy-analytics-chart" className="apex-charts" dir="ltr">
                        <ReactApexChart options={options} series={strategies.filter((data: any) => !(notActiveSet.indexOf(data.id) > -1)).map((data: any) => data)} type="bar" height={332} />
                    </div>
                    {
                        strategies.length > 0 && strategies.map((data: any) => {
                            return (<button key={'btn-serie-' + data.id}
                                            onClick={() => displaySeries(data.id == null ? -1 : data.id)}
                                            style={{'backgroundColor': notActiveSet.indexOf(data.id) > -1 ? 'grey' : data.color,
                                                    'color': 'white'}}
                                            className='btn me-1 mt-1 cursor-pointer'>{data.description}</button>)
                        })
                    }
                </div>
                {
                    loading && forceLoading &&
                    <div className="position-absolute text-center h-100 bg-dark w-100 bg-white opacity-75 top-0">
                        <Spinner className="spinner-border text-info" style={{marginTop: '210px'}} role="status">
                        </Spinner>
                    </div>
                }
            </CardBody>
        </Card>
    );
};
export default withTranslation()(DashboardAnalytics);
