import { createSlice } from "@reduxjs/toolkit";
import {getGames} from "./thunk";

interface gamesStateType {
    games: Array<any>;
    error?: string | object | null | undefined | unknown;
    errorGameMarket?: string | object | null | undefined | unknown;
    loading: boolean;
    requestedId: string;
}
export const initialState: gamesStateType = {
    games: [],
    error: null,
    errorGameMarket: null,
    loading: false,
    requestedId: ''
};

const gamesSlice = createSlice({
    name: "games",
    initialState,
    reducers: {},
    extraReducers: builder => {

        builder.addCase(getGames.pending, (state: any, action: any) => {
            state.loading = true;
            state.requestedId = action.meta.requestId
        });

        builder.addCase(getGames.fulfilled, (state: any, action: any) => {

            if( state.requestedId == action.meta.requestId) {
                state.isOpenGames = action.payload.data.isOpenGames
                state.games = action.payload.data.results;
                state.loading = false;
            }

        });

        builder.addCase(getGames.rejected, (state, action) => {
            state.error = action.payload || null;
            state.loading = false;
        });

    }
});

export default gamesSlice.reducer;
